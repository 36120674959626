import React from "react";

const EngChatNav = () => {
  return (
    <>
      <div className="EngChatNav">
        <div className="EngChatNavText">
          <div className="VerticalLine"></div>
          <h5>Chat with Ankur</h5>
          <div className="VerticalLine"></div>
        </div>
      </div>
    </>
  );
};

export default EngChatNav;