// <-----------------------------  Author:- Armaan Singh ----------------------------------->
import React from "react";

const MembershipLoader = () => {
  return (
    <>
      {/* <div className="loaderMembership"></div> */}
      <div className="loaderMembership">
        <div className="dot_Loader dot1"></div>
        <div className="dot_Loader dot2"></div>
      </div>
    </>
  );
};

export default MembershipLoader;
